import $ from "jquery";

const $doc = $(document);

export default function misc() {
  $doc.ready(function () {
    const imageLeft = $(".wp-block-image.alignleft")
    const imageRight = imageLeft.find("+ .wp-block-image.alignright")

    if (imageRight.length) {
      imageLeft.addClass("wp-block-image--equal-height")
      imageRight.addClass("wp-block-image--equal-height")
    }
    if ($( '.post-type-archive-event .breadcrumbs' ).length) {
      $('.post-type-archive-event .breadcrumb_last').text('Events');
    }
  });
}
