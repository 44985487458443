import $ from "jquery";

const $doc = $(document);
const ticketSvg = `
<svg xmlns="http://www.w3.org/2000/svg" width="28" height="15" viewBox="0 0 28 15" fill="none">
<path d="M27.0045 0.900146H0.994531C0.664556 0.900146 0.394531 1.17017 0.394531 1.50015V5.58015C0.394531 5.85017 0.604491 6.0901 0.904481 6.0901C1.92451 6.0901 2.76443 6.93015 2.76443 7.95005C2.76443 8.97007 1.92438 9.81 0.904481 9.81C0.634456 9.81 0.394531 10.02 0.394531 10.3199V14.3999C0.394531 14.7299 0.664556 14.9999 0.994531 14.9999H27.0045C27.3345 14.9999 27.6045 14.7299 27.6045 14.3999V1.5002C27.6045 1.17022 27.3346 0.900196 27.0045 0.900196V0.900146ZM26.6146 13.9801H7.92458V13.6801C7.92458 13.5002 7.77458 13.3502 7.59461 13.3502C7.41463 13.3502 7.26463 13.5002 7.26463 13.6801V13.9801H1.38463V10.7701C2.73463 10.5301 3.75466 9.3602 3.75466 7.95015C3.75466 6.5402 2.73463 5.37015 1.38463 5.13015V1.92015H7.23463V2.3102C7.23463 2.49018 7.38463 2.64017 7.56461 2.64017C7.74458 2.64017 7.89458 2.49017 7.89458 2.3102V1.92015H26.5846C26.6146 1.92015 26.6146 13.9801 26.6146 13.9801Z" fill="#262D33"/>
<path d="M7.56435 9.33008C7.38437 9.33008 7.23438 9.48008 7.23438 9.66005V10.3201C7.23438 10.5001 7.38437 10.6501 7.56435 10.6501C7.74432 10.6501 7.89432 10.5001 7.89432 10.3201V9.66015C7.9243 9.48008 7.7743 9.33008 7.56435 9.33008Z" fill="#262D33"/>
<path d="M7.56435 7.32007C7.38437 7.32007 7.23438 7.47007 7.23438 7.65004V8.30999C7.23438 8.48997 7.38437 8.63997 7.56435 8.63997C7.74432 8.63997 7.89432 8.48997 7.89432 8.30999V7.65004C7.9243 7.47006 7.7743 7.32007 7.56435 7.32007Z" fill="#262D33"/>
<path d="M7.56435 11.3401C7.38437 11.3401 7.23438 11.4901 7.23438 11.6701V12.33C7.23438 12.51 7.38437 12.66 7.56435 12.66C7.74432 12.66 7.89432 12.51 7.89432 12.33V11.6701C7.9243 11.4601 7.7743 11.3401 7.56435 11.3401Z" fill="#262D33"/>
<path d="M7.56435 3.30005C7.38437 3.30005 7.23438 3.45005 7.23438 3.63002V4.32007C7.23438 4.50005 7.38437 4.65005 7.56435 4.65005C7.74432 4.65005 7.89432 4.50005 7.89432 4.32007V3.66012C7.9243 3.45007 7.7743 3.30005 7.56435 3.30005Z" fill="#262D33"/>
<path d="M7.56435 5.31006C7.38437 5.31006 7.23438 5.46006 7.23438 5.64003V6.29998C7.23438 6.47996 7.38437 6.62996 7.56435 6.62996C7.74432 6.62996 7.89432 6.47996 7.89432 6.29998V5.64003C7.9243 5.46005 7.7743 5.31006 7.56435 5.31006Z" fill="#262D33"/>
</svg>`;
const registerSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
<path d="M20.2561 2.07317H18.7927V0.609746C18.7927 0.39188 18.6764 0.190619 18.4878 0.0816954C18.2992 -0.0272277 18.0667 -0.0272359 17.878 0.0816954C17.6894 0.190627 17.5732 0.3919 17.5732 0.609746V2.07317H16.1097C15.8919 2.07317 15.6906 2.1894 15.5817 2.37805C15.4728 2.56669 15.4728 2.79916 15.5817 2.9878C15.6906 3.17644 15.8919 3.29268 16.1097 3.29268H17.5732V4.7561C17.5732 4.97397 17.6894 5.17523 17.878 5.28415C18.0667 5.39308 18.2992 5.39308 18.4878 5.28415C18.6764 5.17522 18.7927 4.97395 18.7927 4.7561V3.29268H20.2561C20.474 3.29268 20.6752 3.17645 20.7842 2.9878C20.8931 2.79916 20.8931 2.56668 20.7842 2.37805C20.6752 2.18941 20.4739 2.07317 20.2561 2.07317Z" fill="#262D33"/>
<path d="M9.40238 9.02448C10.4697 9.02448 11.4934 8.60051 12.2481 7.84577C13.0029 7.09103 13.4268 6.06739 13.4268 5.00003C13.4268 3.93267 13.0029 2.90897 12.2481 2.15429C11.4934 1.39955 10.4697 0.975586 9.40238 0.975586C8.33501 0.975586 7.31131 1.39955 6.55663 2.15429C5.80189 2.90903 5.37793 3.93267 5.37793 5.00003C5.3792 6.06695 5.80364 7.08988 6.55806 7.84435C7.31247 8.59882 8.3354 9.02322 9.40238 9.02448ZM9.40238 2.19518C10.1463 2.19518 10.8597 2.49069 11.3858 3.01669C11.9118 3.54277 12.2073 4.25613 12.2073 5.00007C12.2073 5.74402 11.9118 6.45737 11.3858 6.98345C10.8597 7.50945 10.1463 7.80496 9.40238 7.80496C8.65843 7.80496 7.94507 7.50945 7.41899 6.98345C6.893 6.45737 6.59749 5.74402 6.59749 5.00007C6.59812 4.25637 6.89387 3.54332 7.41971 3.0174C7.94562 2.49157 8.65867 2.19581 9.40238 2.19518Z" fill="#262D33"/>
<path d="M10.9874 9.7561H6.84107C5.06293 9.75872 3.35854 10.4661 2.10122 11.7235C0.843832 12.9808 0.136411 14.6853 0.133789 16.4634V19.3902C0.133789 19.552 0.198021 19.707 0.312351 19.8214C0.42676 19.9358 0.581823 20 0.743552 20H17.0851C17.2468 20 17.4019 19.9358 17.5163 19.8214C17.6306 19.707 17.6948 19.552 17.6948 19.3902V16.4634C17.6922 14.6852 16.9848 12.9809 15.7274 11.7235C14.4701 10.4661 12.7656 9.75873 10.9876 9.7561H10.9874ZM16.4753 18.7805H1.35324V16.4634C1.35452 15.0084 1.93314 13.6132 2.96203 12.5844C3.99092 11.5555 5.38602 10.9768 6.84111 10.9756H10.9875C12.4426 10.9768 13.8377 11.5555 14.8665 12.5844C15.8954 13.6133 16.4741 15.0084 16.4753 16.4634L16.4753 18.7805Z" fill="#262D33"/>
</svg>`;

export default function initUtilityNav() {
  $doc.ready(function () {
    const registerButton = $(".mg-button--register");
    const ticketButton = $(".mg-button--tickets");
    const separator = $('<li class="utility-li separator"><span>/</span></li>');
    const englishLangLink = $(".utility-link--en");
    const activeLink = $(".utility-link--en"); // TO DO - replace later for active one...

    ticketButton.append(ticketSvg);
    registerButton.append(registerSvg);
    englishLangLink.parent().after(separator); // adds the "/" after EN language selector
    activeLink.addClass("current-lang");
  });
}
