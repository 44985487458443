import $ from "jquery";

const $doc = $(document);

function menuDropdownA11y() {
  /*
  Adapted from: An accessible menu for WordPress
  https://github.com/argenteum/accessible-nav-wp
  Licensed GPL v.2 (http://www.gnu.org/licenses/gpl-2.0.html)
  This work derived from:
  https://github.com/WordPress/twentysixteen (GPL v.2)
  https://github.com/wpaccessibility/a11ythemepatterns/tree/master/menu-keyboard-arrow-nav (GPL v.2)
  */

  let menuContainer = $(".js-header");
  let siteHeaderMenu = menuContainer.find(".js-header-wrapper");

  // If you are using WordPress, and do not need to localise your script, or if you are not using WordPress, then uncomment the next line

  let screenReaderText = {
    expand: "Expand child menu",
    collapse: "Collapse child menu",
  };

  let dropdownToggle = $("<button />", {
    class: "dropdown-toggle",
    "aria-expanded": false,
  }).append(
    $("<span />", { class: "u-sr-only", text: screenReaderText.expand }),
  );
  let svgString = `
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.2794 1.23701L8.2794 16.0375L1.24552 8.75936C0.95247 8.46632 0.512819 8.46632 0.219782 8.75936C-0.0732564 9.05241 -0.0732646 9.49206 0.219782 9.7851L7.93752 17.7959C8.5236 18.382 9.45172 18.382 10.038 17.7959L17.8046 9.7851C17.9512 9.63849 18 9.44324 18 9.29663C18 9.10122 17.9024 8.9058 17.7558 8.71056C17.4627 8.46633 16.9743 8.51514 16.73 8.80818L9.74459 16.0375L9.74459 1.23698C9.74459 0.846148 9.40273 0.504288 9.01189 0.504288C8.62105 0.504288 8.27919 0.846148 8.27919 1.23698L8.2794 1.23701Z" fill="#DC7717"/>
    </svg>`;

  dropdownToggle.append(svgString);

  // Adds the dropdown toggle button
  $(".menu-item-has-children > a").after(dropdownToggle);

  // Adds aria attribute
  siteHeaderMenu.find(".menu-item-has-children").attr("aria-haspopup", "true");

  // Toggles the sub-menu when dropdown toggle button clicked
  siteHeaderMenu.find(".dropdown-toggle").click(function (e) {
    let screenReaderSpan = $(this).find(".u-sr-only");

    e.preventDefault();
    $(this).toggleClass("toggled-on");
    $(this).nextAll(".sub-menu").toggleClass("toggled-on");

    // jscs:disable
    $(this).attr(
      "aria-expanded",
      $(this).attr("aria-expanded") === "false" ? "true" : "false",
    );
    // jscs:enable
    screenReaderSpan.text(
      screenReaderSpan.text() === screenReaderText.expand
        ? screenReaderText.collapse
        : screenReaderText.expand,
    );
  });

  // Adds a class to sub-menus for styling
  $(".sub-menu .menu-item-has-children")
    .parent(".sub-menu")
    .addClass("has-sub-menu");

  // Keyboard navigation
  $(".menu-item a, button.dropdown-toggle").on("keyup", function (e) {
    if ([37, 38, 39, 40].indexOf(e.keyCode) == -1) {
      return;
    }

    switch (e.keyCode) {
      case 37: // left key
        e.preventDefault();
        e.stopPropagation();

        if ($(this).hasClass("dropdown-toggle")) {
          $(this).prev("a").focus();
        } else {
          if (
            $(this).parent().prev().children("button.dropdown-toggle").length
          ) {
            $(this).parent().prev().children("button.dropdown-toggle").focus();
          } else {
            $(this).parent().prev().children("a").focus();
          }
        }

        if ($(this).is("ul ul ul.sub-menu.toggled-on li:first-child a")) {
          $(this)
            .parents("ul.sub-menu.toggled-on li")
            .children("button.dropdown-toggle")
            .focus();
        }

        break;

      case 39: // right key
        e.preventDefault();
        e.stopPropagation();

        if ($(this).next("button.dropdown-toggle").length) {
          $(this).next("button.dropdown-toggle").focus();
        } else {
          $(this).parent().next().children("a").focus();
        }

        if ($(this).is("ul.sub-menu .dropdown-toggle.toggled-on")) {
          $(this).parent().find("ul.sub-menu li:first-child a").focus();
        }

        break;

      case 40: // down key
        e.preventDefault();
        e.stopPropagation();

        if ($(this).next().length) {
          $(this).next().find("li:first-child a").first().focus();
        } else {
          $(this).parent().next().children("a").focus();
        }

        if (
          $(this).is("ul.sub-menu a") &&
          $(this).next("button.dropdown-toggle").length
        ) {
          $(this).parent().next().children("a").focus();
        }

        if (
          $(this).is("ul.sub-menu .dropdown-toggle") &&
          $(this).parent().next().children(".dropdown-toggle").length
        ) {
          $(this).parent().next().children(".dropdown-toggle").focus();
        }

        break;

      case 38: // up key
        e.preventDefault();
        e.stopPropagation();

        if ($(this).parent().prev().length) {
          $(this).parent().prev().children("a").focus();
        } else {
          $(this)
            .parents("ul")
            .first()
            .prev(".dropdown-toggle.toggled-on")
            .focus();
        }

        if (
          $(this).is("ul.sub-menu .dropdown-toggle") &&
          $(this).parent().prev().children(".dropdown-toggle").length
        ) {
          $(this).parent().prev().children(".dropdown-toggle").focus();
        }

        break;
    }
  });
}

function newWindowLinks() {
  $("a[target='_blank']").append(
    '<span class="u-sr-only">Opens in new window</span>',
  );
  $("a.wp-element-button[target='_blank'], a.mg-button[target='_blank']").append(
    '<svg class="external-link" width="23" height="26" viewBox="0 0 23 26" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.39429 14.997L18.739 3.6179L10.764 3.66803V0.936035H22.9999V14.4206H20.5162L20.5618 5.6481L10.2399 17.0272L8.39429 14.997Z" fill="#1B1815" /><rect y="6.21289" width="2.39849" height="19.7875" fill="#1B1815"/><rect x="2.39819" y="26" width="2.63834" height="15.5902" transform="rotate(-90 2.39819 26)" fill="#1B1815"/><rect x="2.39819" y="8.85107" width="2.63834" height="5.99622" transform="rotate(-90 2.39819 8.85107)" fill="#1B1815"/><rect x="15.5903" y="16.7661" width="2.39849" height="6.59584" fill="#1B1815"/></svg >',
  );
}

export default function initA11yFunctions() {
  menuDropdownA11y();
  newWindowLinks();
}
